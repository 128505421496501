import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class BentoService {

  serve = true

  language = localStorage.getItem('hockwong_language') || 'zh'
  guest_uid = 'fmpqpOsipuUFVGgULCkkNWNF2sx1'

  private guild_info = new BehaviorSubject<any>(0);
  private address = new BehaviorSubject<any>(0);
  private address_selected = new BehaviorSubject<any>(0);
  private cart = new BehaviorSubject<any>(0);
  private user = new BehaviorSubject<any>(0);
  private store = new BehaviorSubject<any>(0);
  private pop = new BehaviorSubject<any>(0);
  private refresher = new BehaviorSubject<any>(0);
  private category = new BehaviorSubject<any>(0);

  publishCategory(data: any) {
    this.category.next(data);
  }
  getCategory() {
    return this.category;
  }

  publishCart(data: any) {
    this.cart.next(data);
  }
  getCart() {
    return this.cart;
  }

  publishpop(data: any) {
    this.pop.next(data);
  }
  getback() {
    return this.pop;
  }

  publishrefresher(data: any) {
    this.refresher.next(data);
  }
  getrefresher() {
    return this.refresher;
  }


  publishGuild(data: any) {
    this.guild_info.next(data);
  }
  getGuild() {
    return this.guild_info;
  }

  publishSelectAddress(data: any) {
    this.address_selected.next(data);
  }
  getSelectAddress() {
    return this.address_selected;
  }

  publishAddress(data: any) {
    this.address.next(data);
  }
  getAddress() {
    return this.address;
  }

  publishStore(data: any) {
    this.store.next(data);
  }
  getStore() {
    return this.store;
  }

  publishUser(data: any) {
    this.user.next(data);
  }

  getUser() {
    return this.user;
  }

  urlify(text) {

    var urlRegex = /(https?:\/\/[^\s]+)/g;
    return text ? text.replace(urlRegex, function (url) {
      return '<a target="_system" href="' + url + ' ">' + url + '</a>';
    }) : '';
    // or alternatively
    // return text.replace(urlRegex, '<a href="$1">$1</a>')
  }

  fcmsub(x) {
    if (this.serve == false) {
      // this.fcm.subscribeToTopic(x);
    }
  }

  fcmunsub(x) {
    if (this.serve == false) {
      // this.fcm.unsubscribeFromTopic(x);
    }
  }
}
