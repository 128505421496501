import { Component } from '@angular/core';
import firebase from 'firebase';
import { FIREBASE_CONFIG } from './app.firebase.config';
import { HttpClient } from '@angular/common/http';
import { baseUrl } from './../environments/environment.prod';
import { BentoService } from './bento.service';
import { NavController } from '@ionic/angular';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  constructor(private http: HttpClient, private service: BentoService, private nav : NavController) {
    firebase.initializeApp(FIREBASE_CONFIG)

    firebase.auth().onAuthStateChanged(a =>{

      if(a){

        this.http.get(baseUrl + '/getnewcat').subscribe(a => {
          console.log(a['data'])
          this.service.publishCategory(a['data']);
        })
      }else{

        this.nav.navigateRoot('login')

      }


    })

  

  }
}
